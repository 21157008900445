import React, { useState } from 'react';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import Dashboard from './Components/PanelElement/Dashboard';
import Footer from './Components/Footer';
import { Route, Routes } from 'react-router-dom';
import "./Mainpanel.css"
import Category from './Components/PanelElement/Category';
import News from './Components/PanelElement/News';
import Ads from './Components/PanelElement/Ads';
import Tags from './Components/PanelElement/Tags';

const Mainpanel = () => {

    const [menuBtn, setMenuBtn] = useState(false);

    return (
        <div className={menuBtn ? 'sb-sidenav-toggled' : null}>
            <Header click={() => setMenuBtn(!menuBtn)} />
            <div id='layoutSidenav'>
                <Sidebar />
                <div id="layoutSidenav_content" style={{ paddingTop: "1.5rem" }}>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/category" element={<Category />} />
                        <Route path="/tag" element={<Tags />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/ads" element={<Ads />} />
                        
                    </Routes>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Mainpanel;