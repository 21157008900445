import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import Spinner from "../Components/Spinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AWS from "aws-sdk";
import {useNavigate} from "react-router-dom";
const AuthContext = createContext();
const S3_BUCKET_NAME = "ms-media-news";
const REGION = "eu-north-1";
const s3 = new AWS.S3({
  accessKeyId: "AKIAZVVTQKD4EMZ5WGOE",
  secretAccessKey: "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",
  region: REGION,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = (props) => {
  const [authData, setAuthData] = useState(false);
  const [categoty, setCategoty] = useState([]);
  const [tag, setTag] = useState([]);
  const [news, setNews] = useState([]);
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [redirectToReferer, setRedirectToReferer] = useState(false);

  const login = (email, password, setLoginRedirect) => {
    setLoading(true);
    const cred = {
      email: email,
      password: password,
    };
    axios
      .post("/auth/signin", cred)
      .then((res) => {
        authenticate(res.data, () => {
          setLoginRedirect(true);
          setRedirectToReferer(false);
          setLoading(false);
        });
      })
      .catch((e) => {
        NotificationManager.error(e.response.data.error, "Login Fail", 3000);
        if (typeof window != "undefined") localStorage.removeItem("jwt");
        
        setLoading(false);
      });
  };

  const checkSlug = async (slug) => {
    try {
      await axios.post("/news/check", { slug: slug });
      return true;
    } catch (error) {
      return false;
    }    
  };

  const postNews = (data) => {
    setLoading(true);
    axios
      .post("/news/add", {
        title: data.title,
        description: data.content,
        thumbImage: data.thumbnail,
        slug: data.slug,
        category: data.category,
        briefDescription: data.briefDescription,
        hotStories: data.hotStories,
        editors: data.editors,
        tags: data.tag
      })
      .then((res) => {
        fetchNews();
        setLoading(false);
        navigate('/dashboard')
        NotificationManager.success('Post added successfully', "Success", 2000);
      })
      .catch((e) => {
        NotificationManager.error('Something Went wrong', "Error", 2000);
        setLoading(false);
      });
  };

  const authenticate = (jwt, next) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("jwt", JSON.stringify(jwt));
      axios.defaults.headers.common["Authorization"] = jwt.token;
      setAuthData({
        id: jwt.user._id,
        email: jwt.user.email,
        token: jwt.token,
      });
      next();
    }
  };
  const fetchCategory = () => {
    axios
      .get("/category/list")
      .then((res) => {
        setCategoty(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const fetchTag = () => {
    axios
      .get("/tags/list")
      .then((res) => {
        setTag(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const deleteNews = (id) => {
    axios
      .delete(`/news/delete/${id}`)
      .then((res) => {
        fetchNews()
      })
      .catch((e) => console.log(e));
  }

  const fetchNews = () => {
    axios
      .get("/news/list")
      .then((res) => {
        setNews(res.data.data);
      })
      .catch((e) => console.log(e));
  }

  const getNewsById = async (id) => {
    try {
      const res = await axios
        .get(`/news/getitem/${id}`)
      return res.data
    } catch (error) {
      return false
    }
  }

  const uploadAds = ({url, link}) => {
    axios.post("/ads/add", { thumbnail: link, url:url }).then((res) => {
      fetchAds()
    });
  }
  const fetchAds = () => {
    axios.get("/ads/list").then((res) => {
      setAds(res.data.data)
    });
  }
  const adsThumbUpload = async (selectedFile, url) => {
    setLoading(true);
    if (selectedFile) {
      const params = {
        Bucket: S3_BUCKET_NAME,
        Key: selectedFile.name,
        Body: selectedFile,
        ACL: "public-read",
      };
      return s3.upload(params, (err, data) => {
        if (err) {
          console.error(err);
          setLoading(false);
        } else {
          setLoading(false);
          uploadAds({ url, link: data.Location });
        }
      });
    }
  };

  const logout = () => {};
  useEffect(() => {
    fetchCategory();
    fetchNews();
    fetchTag();
    let token = JSON.parse(localStorage.getItem("jwt"));
    if (token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  }, []);

  const handleUpload = async (selectedFile, formData) => {
    setLoading(true);
    if (selectedFile) {
      const params = {
        Bucket: S3_BUCKET_NAME,
        Key: selectedFile.name,
        Body: selectedFile,
        ACL: "public-read",
      };
      return s3.upload(params, (err, data) => {
        if (err) {
          console.error(err);
          setLoading(false);
        } else {
          setLoading(false);
          postNews({ ...formData, thumbnail: data.Location });
        }
      });
    }
  };

  const value = {
    authData,
    login,
    logout,
    fetchCategory,
    categoty,
    handleUpload,
    checkSlug,
    postNews,
    fetchNews,
    news,
    deleteNews,
    getNewsById,
    adsThumbUpload,
    fetchAds,
    ads,
    fetchTag,
    tag
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Spinner /> : null}
      <NotificationContainer />
      {props.children}
    </AuthContext.Provider>
  );
};

export const currentUser = () => {
  if (typeof window == undefined) return false;

  if (localStorage.getItem("jwt")) return true;
  else return false;
};

export default AuthProvider;
