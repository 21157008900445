import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useAuth } from "../../AuthContext/AuthContext";
import {NotificationManager} from "react-notifications";

const News = () => {
  const { categoty, handleUpload, checkSlug, getNewsById, tag } = useAuth();
  const [slugError, setSlugError] = useState(true);
  const [formData, setformData] = useState({
    title: "",
    briefDescription: "",
    slug: "",
    category: [],
    tag: [],
    content: null,
    hotStories: false,
    editors: false
  });
  const [file, setFile] = useState();
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false ] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
      ],
      ["link", "image"],
    ],
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(file){
      handleUpload(file, formData);
    }else{
      NotificationManager.error('Something Went wrong', "Error", 2000);
    }
  }


  // const setInitData = async (id) => {
  //   const res =  await getNewsById(id)
  //   setformData({
  //     title: res.data.title,
  //     briefDescription: res.data.briefDescription,
  //     slug: res.data.slug,
  //     category: res.data.category,
  //     content: res.data.description
  //   })
  // }

  useEffect(() => {
    if(formData.slug && !localStorage.getItem('updateId')){
      const timeout = setTimeout(() => {
        checkValue()
      }, 2000);
      return () => clearTimeout(timeout)
    }
  }, [formData.slug]);
  const checkValue = async () => {
    setSlugError(await checkSlug(formData.slug))
  }

  return (
    <main style={{ padding: "0px 15px" }}>
      {!slugError && <Alert variant={'danger'}>
          Please update the slug
      </Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Title</Form.Label>
          <Form.Control required value={formData.title} type="text" placeholder="Type Here" onChange={e => setformData({...formData, title: e.target.value, slug:  e.target.value.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')})} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Slug</Form.Label>
          <Form.Control value={formData.slug} type="text" placeholder="Type Here" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Brif Description</Form.Label>
          <Form.Control value={formData.briefDescription} required type="text" placeholder="Type Here" onChange={e => setformData({...formData, briefDescription: e.target.value})} />
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Thumbnail</Form.Label>
          <Form.Control required onChange={(e) => setFile(e.target.files[0])} type="file" />
        </Form.Group>
        <ReactQuill
          className="mb-3"
          theme="snow"
          value={formData.content}
          modules={modules}
          onChange={(e) => setformData({...formData, content: e})}
        />
        <Form.Label>Category</Form.Label>
        <Form.Select className="mb-3" multiple aria-label="Default select example" value={formData.category} onChange={e => setformData({...formData, category: [].slice.call(e.target.selectedOptions).map(item => item.value)})}>
          {categoty.map(d => {
            return <option key={d._id} value={d._id}>{d.title}</option>
          })}
        </Form.Select>
        <Form.Label>Tag</Form.Label>
        <Form.Select className="mb-3" multiple aria-label="Default select example" value={formData.tag} onChange={e => setformData({...formData, tag: [].slice.call(e.target.selectedOptions).map(item => item.value)})}>
          {tag.map(d => {
            return <option key={d._id} value={d._id}>{d.title}</option>
          })}
        </Form.Select>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Hot news"
            onChange={(e) => {
              setformData({...formData, hotStories: e.target.checked})
            }}
            checked={formData.hotStories}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Editor's Choice"
            onChange={(e) => {
              setformData({...formData, editors: e.target.checked})
            }}
            checked={formData.editors}
          />
        </Form.Group>
        <Button type="submit">Add</Button>
      </Form>
    </main>
  );
};

export default News;
