import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import axios from "axios";
import { useAuth } from "../../AuthContext/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { Button, Col, Row, Table } from "react-bootstrap";
import moment from "moment";

const Dashboard = () => {
  const { redirectToReferer, news, deleteNews } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let redirect = null;

  if (redirectToReferer) {
    redirect = <Navigate to="/signin" />;
  }

  const updateNews = (id) => {
    navigate("/news");
    localStorage.setItem("updateId", id);
  };

  return (
    <main style={{ padding: "0px 15px" }}>
      {loading ? <Spinner /> : null}
      {redirect}
      <h3>Articals</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th width={"20%"}>Publish On</th>
            <th width={"20%"}>From Now</th>
            <th width={50}>Action</th>
          </tr>
        </thead>
        <tbody>
          {news?.map((d, index) => {
            return (
              <tr key={d._id}>
                <td>{index + 1}</td>
                <td>{d.title}</td>
                <td>{moment(d.created).format("YYYY-MM-DD hh:MM A")}</td>
                <td>{moment(d.created).fromNow()}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      deleteNews(d._id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#fff"
                        d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                      />
                    </svg>
                  </Button>
                  {/* <Button
                        variant="primary"
                        onClick={() => {
                          updateNews(d._id);
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path  fill="#fff" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                      </Button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </main>
  );
};

export default Dashboard;
