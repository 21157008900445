import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button, Table } from "react-bootstrap";
import { useAuth } from "../../AuthContext/AuthContext";
import {NotificationManager} from "react-notifications";
import moment from "moment/moment";

const Ads = () => {
  const [url, setUrl] = useState("");
  const { adsThumbUpload, ads, fetchAds } = useAuth();
  const [file, setFile] = useState();
  const submitHandle = (e) => {
    e.preventDefault();
    if(file){
      try {
        adsThumbUpload(file, url);
        setUrl("")
        setFile(null)
      } catch (error) {

      }
    }else{
      NotificationManager.error('Something Went wrong', "Error", 2000);
    }
  };

  const deleteHandel = (id) => {
    axios
      .delete(`/ads/delete/${id}`)
      .then((res) => {
        fetchAds();
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    fetchAds();
  }, []);
  return (
    <main style={{ padding: "0px 15px" }}>
      <Row>
        <Col>
          <Form onSubmit={submitHandle}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Here"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Thumbnail</Form.Label>
              <Form.Control
                required
                onChange={(e) => setFile(e.target.files[0])}
                type="file"
              />
            </Form.Group>
            <Button type="submit">Add</Button>
          </Form>
        </Col>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Url</th>
                <th></th>
                <th width={50}>Action</th>
              </tr>
            </thead>
            <tbody>
              {ads?.map((d, index) => {
                return (
                  <tr key={d._id}>
                    <td>{index + 1}</td>
                    <td><img style={{height: 50, }} src={d.thumbnail} /></td>
                    <td>{d.url}</td>
                    <td>{moment(d.created).fromNow()}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandel(d._id);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="#fff"
                            d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                          />
                        </svg>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </main>
  );
};

export default Ads;
